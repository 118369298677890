import * as React from "react";
import {
  connectAutoComplete,
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
} from "react-instantsearch-dom";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { Helmet } from "react-helmet";

function Hit(props) {
  return <Highlight attribute="white" hit={props.hit} />;
}

const Autocomplete = ({ hits, currentRefinement, refine }) => (
  <ul>
    <li>
      <input
        type="search"
        value={currentRefinement}
        onChange={(event) => refine(event.currentTarget.value)}
      />
    </li>
    {hits.map((hit) => (
      <li key={hit.id}>
        <h3>
          <Highlight attribute="english" hit={hit} />
        </h3>
        <em>Green:</em>
        <Highlight attribute="green" hit={hit} />
        <br />
        <em>White:</em>
        <Highlight attribute="white" hit={hit} />
      </li>
    ))}
  </ul>
);
const CustomAutocomplete = connectAutoComplete(Autocomplete);

const searchClient = instantMeiliSearch(
  "https://txhais.mike.xyz",
  process.env.GATSBY_MEILISEARCH_PUBLIC_KEY,
  {
    placeholderSearch: false,
  }
);

// markup
const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>Home Page</title>
      </Helmet>

      <InstantSearch indexName="words" searchClient={searchClient}>
        <CustomAutocomplete />
      </InstantSearch>
    </main>
  );
};

export default IndexPage;
